import React from 'react'
import LeadsItem from './LeadsItem'

const LeadsList = ({ openLeads, closedLeads }) => {
  return (
        <div className='leads__list'>
            {openLeads && openLeads.map(el =>
                    <LeadsItem key={el.id} leadInfo={el}></LeadsItem>
            )
            }
            {closedLeads && closedLeads.map(el =>
                    <LeadsItem key={el.id} leadInfo={el}></LeadsItem>
            )
            }
        </div>
  )
}

export default LeadsList
