import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface IVendorLocations {
    serviceAreas?: string | undefined,
    locations: any[]
}

const VendorLocations: FC<IVendorLocations> = ({ serviceAreas, locations }: IVendorLocations) => {
  let locationsMarkup
  if (locations.length > 0) {
    locationsMarkup = []
    locations.forEach((location) => {
      locationsMarkup.push(<div key={location.translation_key}>
                <FormattedMessage
                    id={`locations.${location.translation_key}`}
                    defaultMessage={location.name_default}/>
            </div>)
    })
  } else {
    locationsMarkup = <div>{serviceAreas}</div>
  }
  return (
        <div className='vendor__info_location'>{locationsMarkup}</div>
  )
}

export default VendorLocations
