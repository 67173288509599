import React from 'react'
import { observer } from 'mobx-react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PhoneWithCode from '../../editor/ReactQuill/PhoneWithCode'

const VendorRequestForm = observer(({ model, intl, defaultPhoneCountry }) => {
  return (
        <div className="vendor__form col p-4">
            <FormattedMessage tagName='h6' id='vendors.request.heading' defaultMessage='Send a request'/>
            <form id='vendor_request_form'>
                <input type='hidden' name='authenticity_token'
                       value={document.querySelector('[name="csrf-token"]')?.content}/>
                <div className='w-100 mb-3'>
                    <label htmlFor='name'>
                        <FormattedMessage id='vendors.request.name' defaultMessage='Your name'/>
                    </label>
                    <input
                        onChange={event => model.leadChangeName(event.target.value)}
                        onClick={() => model.setIsNameEmpty(false)}
                        value={model.userInput.name}
                        placeholder={intl.formatMessage({
                          id: 'vendors.name_input_placeholder',
                          defaultMessage: 'John'
                        })}
                        className={model.isNameEmpty ? 'error' : ''}
                        id='name'
                        name='name'
                        type="text"
                    />
                </div>
                <div className='w-100 mb-3'>
                    <label htmlFor='phone'>
                        <FormattedMessage id='vendors.request.phone_number' defaultMessage='Phone number'/>
                    </label>
                    <PhoneWithCode
                        country={defaultPhoneCountry}
                        countryCode={model.userInput.countryCode}
                        countryCodeCallback={model.leadChangeCountryCode.bind(model)}
                        phone={model.userInput.phone}
                        phoneCallback={model.leadChangePhone.bind(model)}
                        phoneClassname={model.isPhoneEmpty ? 'error' : ''}
                        clearError={() => {
                          model.setIsPhoneEmpty(false)
                        }}
                        readOnly={false}
                    />
                </div>
                <div className='w-100 position-relative'>
                    <label htmlFor='desc'>
                        <FormattedMessage id='vendors.request.comments' defaultMessage='Comment'/>
                    </label>
                    <textarea
                        onChange={event => model.leadChangeComment(event.target.value)}
                        value={model.userInput.comment}
                        placeholder=""
                        name="comment"
                        id="comment"
                        cols="30"
                        rows="10"
                    >
                    </textarea>
                    <span className='character_limit'>
                        {model.userInput.comment.length}/250
                    </span>
                </div>
                <div className="actions mt-4 w-100">
                    <input
                        type="button"
                        disabled={model.saveButtonDisabled}
                        onClick={() => {
                          if (!model.isInfoEmpty) {
                            model.saveLeadOnServer()
                          }
                        }}
                        value={intl.formatMessage({
                          id: 'vendors.request.heading',
                          defaultMessage: 'Send a request'
                        })}
                        className="main_submit button_input"/>
                </div>
            </form>
        </div>
  )
})

const VendorRequestFormMobileSubmitBody = observer(({ model, intl }) => {
  return (
        <input
            type="button"
            disabled={model.saveButtonDisabled}
            onClick={() => {
              if (!model.isInfoEmpty) {
                model.saveLeadOnServer()
              } else {
                const requestForm = document.querySelector('.vendor__form')
                requestForm.scrollIntoView({ behavior: 'smooth', block: 'center' })
              }
            }}
            value={intl.formatMessage({
              id: 'vendors.request.heading',
              defaultMessage: 'Send a request'
            })}
            className="main_submit w-100 d-flex gap-2 align-items-center justify-content-center"/>
  )
})

export const VendorRequestFormMobileSubmit = injectIntl(VendorRequestFormMobileSubmitBody)
export default injectIntl(VendorRequestForm)
