import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import ImageGallery from 'react-image-gallery'

interface IVendorImageGallery {
  model: any
  carouselURLs: string[]
  isMobile?: boolean | undefined
}

const VendorImageGallery: FC<IVendorImageGallery> = observer(({ model, carouselURLs, isMobile }) => {
  const items = carouselURLs.map(url => ({ original: url }))

  if (isMobile) {
    return (
      <div className='vendor_images_container mobile w-100'>
        {model.isGalleryOpen
          ? <div onClick={() => model.setIsGalleryOpen(false)} className='close_btn d-flex align-items-center'></div>
          : <div className='w-100 show_more_btn_container w-100 d-flex align-items-center justify-content-center'>
              <div onClick={() => model.setIsGalleryOpen(true)} className='show_more_btn d-flex align-items-center gap-1'>
                <FormattedMessage id='vendors.gallery_show_all' defaultMessage='Show all' /> ({carouselURLs.length})
              </div>
            </div>
        }
        {model.isGalleryOpen
          ? <ImageGallery useBrowserFullscreen items={items} />
          : <div className='vendor__page_image'>
            <div className='vendor_image d-flex justify-content-center'>
              <img src={carouselURLs[0]} alt='Image'/>
            </div>
          </div>
        }
      </div>
    )
  } else {
    return (
      <div className='vendor_images_container w-100'>
        {model.isGalleryOpen
          ? <div onClick={() => model.setIsGalleryOpen(false)} className='close_btn d-flex align-items-center'></div>
          : <div onClick={() => model.setIsGalleryOpen(true)} className='show_more_btn d-flex align-items-center gap-1'>
              <FormattedMessage id='vendors.gallery_show_all' defaultMessage='Show all' /> ({carouselURLs.length})
            </div>
        }
        {model.isGalleryOpen
          ? <ImageGallery useBrowserFullscreen items={items} />
          : <div className='vendor__page_images'>
            {carouselURLs.slice(0, 6).map((url: string) =>
              (
                <div key={url}
                  className={`vendor__image-grid vendor__image-grid_${carouselURLs.indexOf(url) + 1}`}>
                  <img src={url} alt='Image'/>
                </div>
              )
            )}
          </div>
        }
      </div>
    )
  }
})

export default VendorImageGallery
