import { observer } from 'mobx-react'
import React, { FC } from 'react'

interface ISortButton {
    model: any
}

const SortButton: FC<ISortButton> = observer(({ model }) => {
  return (
    <div className='sort_button_wrap align-self-center'>
      <button onClick={() => model.setIsSortModalOpen(!model.isSortModalOpen)} className='sort_button'>Фильтр</button>
      {model.isSortModalOpen &&
        <form className='sort_modal d-flex flex-column gap-2'>
            <div className='d-flex align-items-center gap-3'>
                <input type="radio" name="sort_modal_option" id="popular" value="Популярные" />
                <label htmlFor="popular">Популярные</label>
            </div>
            <div className='d-flex align-items-center gap-3'>
                <input type="radio" name="sort_modal_option" id="new" value="Сначала новые" />
                <label htmlFor="new">Сначала новые</label>
            </div>
            <div className='d-flex align-items-center gap-3'>
                <input type="radio" name="sort_modal_option" id="recommend" value="Рекомендации" />
                <label htmlFor="recommend">Рекомендации</label>
            </div>
        </form>
      }
    </div>
  )
})

export default SortButton
