import React, { useState } from 'react'
import LeadsPopUp from './LeadsPopUp'

const LeadsItem = ({ leadInfo }) => {
  let status = ''

  if (leadInfo.status == undefined) { // TODO: Удалить этот код после добавления логики статусов
    leadInfo.status = 'Need to add the status attribute to the database'
  }

  if (leadInfo.status != 'process') {
    status = leadInfo.status.charAt(0).toUpperCase() + leadInfo.status.slice(1)
  } else {
    status = 'In process'
  }

  const [dotsAction, setDotsAction] = useState(false)
  const [popUpState, setPopUpState] = useState(false)

  const handleDotsAction = () => {
    setDotsAction(!dotsAction)
  }

  const handlePopUpActivation = (state) => {
    setPopUpState(state)
  }

  return (
        <div>
            <div className='leads__item row'>
                <div className="lead__info col-2">{leadInfo.name}</div>
                <div className="lead__info col-2">{leadInfo.date}</div>
                <div className="lead__info col-2 status" id={leadInfo.status}><div>{status}</div></div>
                <div className="lead__info col-2 budget">{leadInfo.budget}</div>
                <div className="lead__info col-3 notes">{leadInfo.notes}</div>
                <div className="lead__info col-1 dots"><div className='dots__action_handler' onClick={handleDotsAction}>
                    {dotsAction &&
                        <div className='dots__action'>
                            <div onClick={() => setPopUpState(true)} className='dots__action_child first py-1 px-3'>View details</div>
                            <div className='dots__action_child second py-1 px-3'>Edit Request</div>
                        </div>}
                    </div></div>

            </div>
            <div className='leads__item_mobile mb-4 container d-flex justify-content-between'>
                <div className='leads__item'>
                    <div className='d-flex my-2 justify-content-between'>
                        <div className="lead__info option__text">Name</div>
                        <div className="lead__info">{leadInfo.name}</div>
                    </div>
                    <div className='d-flex my-2 justify-content-between'>
                        <div className="lead__info option__text">Creation date</div>
                        <div className="lead__info">{leadInfo.date}</div>
                    </div>
                    <div className='d-flex my-2 justify-content-between'>
                        <div className="lead__info option__text">Status</div>
                        <div className="lead__info status" id={leadInfo.status}><div>{status}</div></div>
                    </div>
                    <div className='d-flex my-2 justify-content-between'>
                        <div className="lead__info option__text">Budget</div>
                        <div className="lead__info budget">{leadInfo.budget}</div>
                    </div>
                    <div className='d-flex mt-4 flex-column'>
                        <div className="lead__info option__text">My Notes</div>
                        <div className="lead__info notes">{leadInfo.notes}</div>
                    </div>
                </div>
                <div className="lead__info dots"><div className='dots__action_handler' onClick={handleDotsAction}>
                    {dotsAction &&
                        <div className='dots__action'>
                            <div onClick={() => setPopUpState(true)} className='dots__action_child first py-1 px-3'>View details</div>
                            <div className='dots__action_child second py-1 px-3'>Edit Request</div>
                        </div>}
                    </div></div>
            </div>
            <LeadsPopUp active={popUpState} close={handlePopUpActivation} leadFullName={leadInfo.leadFullName} leadEmail={leadInfo.leadEmail} dateOfEvent={leadInfo.dateOfEvent} commentaries={leadInfo.commentaries}></LeadsPopUp>
        </div>
  )
}

export default LeadsItem
