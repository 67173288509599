import React from 'react'
import LeadsList from './leads/LeadsList'

export default class LeadsApp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openLeads: props.openLeads || [],
      closedLeads: props.closedLeads || [],
      clients_count: (props.openLeads || []).length + (props.closedLeads || []).length
    }
  }

  render () {
    return (
            <React.Fragment>
                <div className="clients__count">
                    <p>Total clients: <span>{this.state.clients_count}</span></p>
                </div>
                <div className="current__leads">
                    <h1 className='head__text my-3'>Leads</h1>
                    <div>
                        <div className='leads__item_info row'>
                            <div className="lead__info option__text col-2">Name</div>
                            <div className="lead__info option__text col-2">Creation date</div>
                            <div className="lead__info option__text col-2">Status</div>
                            <div className="lead__info option__text col-2">Budget</div>
                            <div className="lead__info option__text col-3">My Notes</div>
                            <div className="lead__info option__text col-1"><div></div></div>
                        </div>
                        <LeadsList openLeads={this.state.openLeads}></LeadsList>
                    </div>
                </div>
                <div className="current__leads">
                    <h1 className='head__text my-3'>Closed leads</h1>
                    <div>
                        <div className='leads__item_info row'>
                            <div className="lead__info option__text col-2">Name</div>
                            <div className="lead__info option__text col-2">Creation date</div>
                            <div className="lead__info option__text col-2">Status</div>
                            <div className="lead__info option__text col-2">Budget</div>
                            <div className="lead__info option__text col-3">My Notes</div>
                            <div className="lead__info option__text col-1"><div></div></div>
                        </div>
                        <LeadsList closedLeads={this.state.closedLeads}></LeadsList>
                    </div>
                </div>
                <div className="w-100"></div>
            </React.Fragment>
    )
  }
}
