import React, { FC } from 'react'

const CircularLoading: FC = () => {
  return (
    <div className="circular_loader">
        <div className="loader"></div>
    </div>
  )
}

export default CircularLoading
