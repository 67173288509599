import React from 'react'
import { FormattedMessage } from 'react-intl'
import VendorPageModel from './models/VendorPageModel'
import VendorRequestForm, { VendorRequestFormMobileSubmit } from './VendorRequestForm'
import TranslationProvider from '../../providers/TranslationProvider'
import VendorImageGallery from './VendorImageGallery'
import VendorLocations from './VendorLocations'

export default class VendorPageApp extends React.Component {
  constructor (props) {
    super(props)
    this.model = new VendorPageModel({
      vendorID: props.vendor_id,
      locale: props.locale,
      defaultCountryCode: props.defaultCountryCode
    })

    this.vendor_info = {
      mainName: props.mainName,
      photoURL: props.photoURL,
      carouselURLs: props.carouselURLs,
      serviceAreas: props.service_areas,
      aboutVendor: props.aboutVendor,
      employeesCount: props.employeesCount,
      timeInBusiness: props.timeInBusiness,
      positiveReviews: props.positiveReviews,
      services: props.services,
      locations: props.locations
    }

    this.locale = props.locale
    this.defaultPhoneCountry = props.defaultPhoneCountry
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorPageApp mounted')
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <div className="vendor__page_wrap d-flex flex-column align-items-center gap-3 gap-md-5 w-100 h-100">
                    <VendorImageGallery model={this.model} carouselURLs={this.vendor_info.carouselURLs}/>
                    <div className='vendor__page_block row align-items-start w-100'>
                        <div className="vendor__info col mb-4">
                            <div className="vendor__info_main d-flex gap-3">
                                <div className='vendor__info_image'>
                                    <img src={this.vendor_info.photoURL} alt='Vendor avatar'/>
                                </div>
                                <header className="vendor__info_text mb-3">
                                    <h1>{this.vendor_info.mainName}</h1>
                                    <VendorLocations serviceAreas={this.vendor_info.serviceAreas}
                                                     locations={this.vendor_info.locations}/>
                                </header>
                            </div>
                            <VendorImageGallery isMobile model={this.model}
                                                carouselURLs={this.vendor_info.carouselURLs}/>
                            <section id="about" className='about_vendor mt-4'>
                                <FormattedMessage tagName="h2" id='vendors.about_us_heading' defaultMessage='About us'/>
                                <p className='vendor__info_about mt-4 mb-1'
                                   dangerouslySetInnerHTML={{ __html: this.vendor_info.aboutVendor }}>
                                </p>
                            </section>
                            <section id="services" className="vendor__info_details mt-5">
                                <FormattedMessage className='vendor__info_details_text' tagName="h2"
                                                  id='vendors.services_heading' defaultMessage='Services and Prices'/>
                                <div className='vendor__info_details_list mt-4'
                                     dangerouslySetInnerHTML={{ __html: this.vendor_info.services }}>
                                </div>
                            </section>
                        </div>
                        <VendorRequestForm
                            model={this.model}
                            defaultPhoneCountry={this.defaultPhoneCountry}
                        />
                        <div className='send_request_fixed w-100'>
                            <div className="actions w-100">
                                <VendorRequestFormMobileSubmit model={this.model}/>
                            </div>
                        </div>
                    </div>
                </div>
            </TranslationProvider>
    )
  }
}
