import React, { FC } from 'react'
import SupplierItem from './SupplierItem'

interface ISuppliersList {
    suppliers?: any
    popularVendors: any
}

const SuppliersList: FC<ISuppliersList> = ({ popularVendors }) => {
  return (
    <div className='block popular_suppliers'>
        <div className='block_content d-flex justify-content-between gap-5 mb-2 mb-md-4'>
        <h3>Популярные поставщики</h3>
        </div>
        <div className='suppliers d-flex gap-3'>
            {popularVendors.map((supplier: any) =>
                <SupplierItem supplier={supplier} key={`supplier_popular_${supplier.id}`} />
            )}
        </div>
    </div>
  )
}

export default SuppliersList
