import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { successfully_vendor_leads_path, vendor_leads_path } from '../../../../routes.js.erb'

class VendorPageModel {
  userInput
  serverPath
  serverMethod
  isGalleryOpen
  isNameEmpty
  isPhoneEmpty
  tempNumberRegex
  saveButtonDisabled

  constructor ({ vendorID, locale, defaultCountryCode }) {
    if (vendorID) {
      this.userInput = {
        name: '',
        vendor_id: vendorID,
        countryCode: defaultCountryCode || '+1',
        phone: '',
        comment: ''
      }
      this.serverPath = vendor_leads_path(locale, vendorID)
      this.successfullyPath = successfully_vendor_leads_path(locale, vendorID)
    }
    this.serverMethod = 'POST'
    this.isGalleryOpen = false
    this.isNameEmpty = false
    this.isPhoneEmpty = false
    this.tempNumberRegex = /^[0-9]*$/
    this.saveButtonDisabled = false

    makeObservable(this, {
      userInput: observable,
      isGalleryOpen: observable,
      isNameEmpty: observable,
      isPhoneEmpty: observable,
      saveButtonDisabled: observable,
      setIsNameEmpty: action,
      setIsPhoneEmpty: action,
      setIsGalleryOpen: action,
      leadChangeName: action,
      leadChangeCountryCode: action,
      leadChangePhone: action,
      leadChangeComment: action,
      isInfoEmpty: computed
    })
  }

  changeSaveButtonDisabled (new_status) {
    this.saveButtonDisabled = new_status
  }

  setIsGalleryOpen (isOpen) {
    this.isGalleryOpen = isOpen
  }

  leadChangeName (name) {
    this.userInput.name = name
  }

  leadChangeCountryCode (countryCode) {
    this.userInput.countryCode = countryCode
  }

  leadChangePhone (phone) {
    if (this.tempNumberRegex.test(phone)) {
      this.userInput.phone = phone
    }
  }

  leadChangeComment (comment) {
    if (comment.length <= 250) {
      this.userInput.comment = comment
    }
  }

  setIsNameEmpty (isEmpty) {
    this.isNameEmpty = isEmpty
  }

  setIsPhoneEmpty (isEmpty) {
    this.isPhoneEmpty = isEmpty
  }

  get isInfoEmpty () {
    if (this.userInput.name.length === 0) {
      this.setIsNameEmpty(true)
      return true
    }

    if (this.userInput.phone.length === 0) {
      this.setIsPhoneEmpty(true)
      return true
    }

    return false
  }

  saveLeadOnServer () {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const that = this
    that.changeSaveButtonDisabled(true)
    fetch(this.serverPath, {
      method: this.serverMethod,
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_input: toJS(this.userInput) })
    })
      .then(response => {
        if (response.ok) {
          that.navigateToSuccessfullyPage()
        } else {
          that.changeSaveButtonDisabled(false)
          return response.json().then(data => {
            if (response.status === 422) {
              if (data.errors?.name) {
                this.setIsNameEmpty(true)
              }
              if (data.errors?.phone) {
                this.setIsPhoneEmpty(true)
              }
            } else {
              throw new Error('Error: Something went wrong')
            }
          })
        }
      })
      .catch(error => {
        that.changeSaveButtonDisabled(false)
        throw new Error(error.message)
      })
  }

  navigateToSuccessfullyPage () {
    location.href = this.successfullyPath
  }
}

export default VendorPageModel
