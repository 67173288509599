import React, { FC } from 'react'
import CakeLogo from '../../../../assets/images/vendors/icons/cake_logo.svg'
import Star from '../../../../assets/images/star.svg'

interface ISupplierItem {
    supplier: any
}

const SupplierItem: FC<ISupplierItem> = ({ supplier }) => {
  return (
        <div className='supplier'>
            <img className='supplier_img' alt='Cakes' src={supplier.image_url} />
            <div className='supplier_content d-flex align-items-center w-100'>
            <img className='supplier_logo' alt='CakeLogo' src={CakeLogo} />
            <div className='ml-2'>
                <h6>{supplier.name}</h6>
                <p>
                <img alt='Star' width='24px' height='24px' src={Star} /> 5 <span>(5 отзывов)</span>
                </p>
            </div>
            <span className='advertisement align-self-end ml-auto'>Реклама</span>
            </div>
        </div>
  )
}

export default SupplierItem
