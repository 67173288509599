import React from 'react'

const LeadsPopUp = ({ active, close, leadFullName, leadEmail, dateOfEvent, commentaries }) => {
  if (active) {
    return (
        <div className="popup__details d-flex align-items-center justify-content-center">
            <div className="popup__main">
                <div className="popup__head d-flex justify-content-between">
                    <h2>Request Details</h2>
                    <div onClick={() => close(false)} className='popup__close'></div>
                </div>
                <div className="popup__user-info popup__info row row-cols-sm-2 row-cols-1 mt-3 mb-4">
                    <div className="popup__info popup__fullname">
                        <h6>Full Name</h6>
                        <p>{leadFullName}</p>
                    </div>
                    <div className="popup__info popup__fullname mt-sm-0 mt-4">
                        <h6>Email Address</h6>
                        <p>{leadEmail}</p>
                    </div>
                </div>
                <div className="popup__date popup__info mb-4">
                    <h6>Date of the event</h6>
                    <p>{dateOfEvent}</p>
                </div>
                <div className="popup__commentaries popup__info">
                    <h6>Commentaries</h6>
                    <p>{commentaries}</p>
                </div>
            </div>
        </div>
    )
  } else {
    return (null)
  }
}

export default LeadsPopUp
