import React from 'react'
import SuppliersList from '../suppliers/SuppliersList'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

export default class VendorsApp extends React.Component {
  constructor (props) {
    super(props)
    this.vendorCategories = props.vendorCategories
    this.popularVendors = props.popularVendors
    this.locale = props.locale
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
          <div className='d-flex w-100 justify-content-center'>
            <div className='vendors_wrap w-100'>
                <div className='find_vendors w-100 d-flex align-items-center'>
                  <div className='find_vendors_content w-100'>
                    <div className='find_vendors-text mt-4'>
                      <h1>
                        Находите проверенные event-услуги в 3 клика
                      </h1>
                      <div className='w-100 content_line'></div>
                      <p>Event-услуги под любой бюджет, праздник и стиль</p>
                    </div>
                    <div className='d-flex flex-wrap gap-3 mt-5'>
                        <select placeholder='Choose a category'>
                          <option>Category</option>
                          <option>Category</option>
                          <option>Category</option>
                        </select>
                        <select placeholder='Choose a city'>
                          <option>City</option>
                          <option>City</option>
                          <option>City</option>
                        </select>
                        <button className='black_button'>Поиск</button>
                      </div>
                  </div>
                </div>

              {this.vendorCategories.length > 0 && (
                <div className='block popular_categories'>
                  <div className='block_content d-flex justify-content-between align-items-center mb-2 mb-md-4'>
                    <h3>Популярные категории</h3>
                    <button className='black_button'>Все категории</button>
                  </div>
                  <div className='categories_cards d-flex flex-wrap justify-content-around'>
                    {this.vendorCategories.map(category =>
                      <figure key={`vendor_category_${category.id}`}>
                        <img alt={category.name_default} src={category.thumbnail_url} />
                        <FormattedMessage tagName='figcaption' id={`vendors.categories.${category.translation_key}`} defaultMessage={category.name_default} />
                      </figure>
                    )}
                  </div>
                  <button className='black_button mobile mt-4'>Все категории</button>
                </div>
              )}

                <SuppliersList popularVendors={this.popularVendors} />

                <div className='block supplier_steps simple_info_block'>
                  <h3>
                    Как найти поставщика?
                  </h3>
                  <div className='d-flex mt-5 flex-column align-items-center align-items-md-start flex-md-row walkthrough'>
                    <div className='info_step'>
                      <div className='d-flex align-items-center justify-content-center mb-3'>
                        <h2>1. Поиск</h2>
                      </div>
                      <p>Благодаря удобному поиску и фильтрам найти подходящего поставщика</p>
                    </div>
                    <div className='next_arrow d-none d-md-block mt-3'></div>
                    <div className='next_arrow mobile d-block d-md-none'></div>
                    <div className='info_step'>
                      <div className='d-flex align-items-center justify-content-center mb-3'>
                        <h2>2. Детали</h2>
                      </div>
                      <p>Подробно изучить все детали от фотографий с мероприятий до цен на услуги</p>
                    </div>
                    <div className='next_arrow d-none d-md-block mt-3'></div>
                    <div className='next_arrow mobile d-block d-md-none'></div>
                    <div className='info_step'>
                      <div className='d-flex align-items-center justify-content-center mb-3'>
                        <h2>3. Связаться</h2>
                      </div>
                      <p>В 1 клика связаться с поставщиком и получить лучшее предложение под ваш ивент </p>
                    </div>
                  </div>
                </div>

                <div className='partnership w-100 d-flex align-items-center mt-5'>
                  <div className='partnership_content w-100'>
                    <div className='partnership-text h-100 d-flex flex-column justify-content-between'>
                      <h1>
                        Начните получать больше заказов став нашими партнерами
                      </h1>
                      <button className='black_button'>Оставить заявку</button>
                    </div>
                  </div>
                </div>

                <div className='block all_services'>
                  <h3>
                    Все категории event-услуг
                  </h3>
                  <div className='d-flex mt-5 flex-column align-items-center align-items-md-start flex-md-row walkthrough'>
                    <div className='d-flex flex-wrap gap-4 w-100 justify-content-center justify-content-md-between'>
                      <ul className='d-flex flex-column gap-4'>
                        <li>Фотографы</li>
                        <li>Музыканты</li>
                        <li>Кайтеринг</li>
                        <li>Доставка</li>
                        <li>Типография</li>
                        <li>Djs</li>
                        <li>Декораторы</li>
                      </ul>
                      <ul className='d-flex flex-column gap-4'>
                        <li>Видеографы</li>
                        <li>Танцевальные уроки</li>
                        <li>Гостиницы</li>
                        <li>Свадебные планеры</li>
                        <li>Доставка</li>
                        <li>Транспорт</li>
                        <li>Ведущие</li>
                      </ul>
                      <ul className='d-flex flex-column gap-4'>
                        <li>Алкоголь</li>
                        <li>Локации</li>
                        <li>Кондитеры</li>
                        <li>Детские праздники</li>
                        <li>Декораторы</li>
                        <li>Ивент-планеры</li>
                        <li>Видеографы</li>
                      </ul>
                      <ul className='d-flex flex-column gap-4'>
                        <li>Танцевальные уроки</li>
                        <li>Обрудование</li>
                        <li>Гостиницы</li>
                        <li>Свадебные планеры</li>
                        <li>Доставка</li>
                        <li>Ивент-планеры</li>
                        <li>Фотографы</li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </TranslationProvider>
    )
  }
}
