import React, { Component } from 'react'
import SuppliersList from '../suppliers/SuppliersList'
import VendorsSearchModel from './model/VendorsSearchModel'
import SortButton from './SortButton'
import VendorServiceItem from './VendorServiceItem'

type Vendor = {
  id: number;
  name: string;
  location: string;
  description: string
  image_url: string
};

interface VendorsSearchAppProps {
  vendors: Vendor[];
  locale: string;
}

export default class VendorsSearchApp extends Component<VendorsSearchAppProps> {
  model
  locale: string
  vendors: Vendor[]

  constructor (props: VendorsSearchAppProps) {
    super(props)
    this.model = new VendorsSearchModel()
    this.locale = props.locale
    this.vendors = props.vendors
  }

  render () {
    return (
      <div className='search_vendor'>
        <SuppliersList popularVendors={[{ name: 'Blah ha' }]}/>
        <div className="brand_content_line mt-5 mb-5"></div>
        <div className="search_header mt-3 mt-md-0 d-flex justify-content-between">
          <div className='d-flex mb-3 mb-md-0 flex-column flex-md-row gap-3 align-items-center flex-wrap'>
            <select className='search_select' name="" id="">
              <option>Кейтеринг</option>
            </select>
            <select className='search_select' name="" id="">
              <option>Алматы</option>
            </select>
            <div className="black_button">Поиск</div>
          </div>
          <SortButton model={this.model} />
        </div>
        <div className="search_service_list mt-5">
          {this.vendors.map((vendor, index) => (
              <VendorServiceItem key={index} vendor={vendor} /> // TODO: isPromo атрибут boolean для отображения Промо
          ))}

          <div className="ad_banner w-100">
            <div className="ad_banner_content p-4 w-100">
              <h1>
                Лучшие флористы города Алматы!
                <p>Скидка новым клиентами 20%</p>
              </h1>
            </div>
            <div className="promo_info pt-1 pb-1">
                Промо
            </div>
          </div>

        </div>
      </div>
    )
  }
}
