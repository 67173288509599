import { action, makeObservable, observable } from 'mobx'

class VendorsSearchModel {
  isSortModalOpen: boolean

  constructor () {
    this.isSortModalOpen = false

    makeObservable(this, {
      isSortModalOpen: observable,
      setIsSortModalOpen: action
    })
  }

  setIsSortModalOpen (isOpen: boolean) {
    this.isSortModalOpen = isOpen
  }
}

export default VendorsSearchModel
