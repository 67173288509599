import React, { FC } from 'react'
import SearchServiceImg from '../../../../assets/images/vendors/main_stock/catering.jpg'
import CakeLogo from '../../../../assets/images/vendors/icons/cake_logo.svg'
import Heart from '../../../../assets/images/vendors/icons/heart.svg'
import Star from '../../../../assets/images/star.svg'

type Vendor = {
    id: number;
    name: string;
    location: string;
    description: string
    image_url: string
};

interface IVendorServiceItem {
    isPromo?: boolean
    vendors: Vendor[];
}

const VendorServiceItem: FC<IVendorServiceItem> = ({ isPromo, vendor }) => {
  return (
    <div className={isPromo ? 'search_service_card promo' : 'search_service_card'}>
        <div className="like_button d-flex align-items-center justify-content-center">
            <img width='18px' height='15px' src={Heart} alt="Heart" />
        </div>
        <div className="search_service_img">
            <img src={SearchServiceImg} alt="ServiceImg" />
        </div>
        <div className="search_service_info">
            <div className="search_service_header d-flex">
                <img width='40px' height='40px' className='service_logo' alt='CakeLogo' src={CakeLogo} />
                <div className='ms-2'>
                    <h6>{vendor.name}</h6>
                    <p>
                    <img alt='Star' width='24px' height='24px' src={Star} /> 4.9 <span>(5 отзывов)</span>
                    </p>
                </div>
                <span className='advertisement align-self-start ms-auto'>Алматы, Казахстан</span>
            </div>
            <div className="search_service_description mt-3">
                <p>
                Банкетная служба Muscat Catering с 2012 года предоставляет полный цикл услуг по организации выездного банкетного обслуживания...
                </p>
            </div>
        </div>
        {isPromo &&
            <div className="promo_info pt-1 pb-1">
                Промо
            </div>
        }
    </div>
  )
}

export default VendorServiceItem
